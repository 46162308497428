<template>
	<div class="notification-list">
		<CustomTableNotification
			:total-items="totalItems"
			:total-pages="totalPages"
			:recent-filter="recentFilters"
			@create="goToCreateForm"
			@search="search"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headers"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
						>{{ header.title }}</th
					>
				</tr>
			</template>

			<template #body>
				<template v-if="notifications && notifications.length">
					<tr
						v-for="(notification, index2) in notifications"
						:key="`${notification.senderId}-${index2}`"
					>
						<td scope="row" class="table-no">{{ getNo(index2) }}</td>
						<td scope="row" class="text-break text-pre-wrap title">{{ notification.title }}</td>
						<td scope="row" class="text-break text-pre-wrap body">{{ notification.body }}</td>
						<td scope="row" class="text-break text-pre-wrap receiver">
							<div v-if="shouldShowUser(notification)">
								{{ getNotiLabel(notification.groupUser) }}
							</div>
							<div v-else>
								<span v-for="(item, index3) in notification.userId" :key="item.id">
									<router-link
										:to="{
											name: 'CustomerDetail',
											params: { id: item.id },
										}"
										target="_blank"
										class="link-icon link-redirect"
									>
										{{ item.name }} <span v-if="index3 < notification.userId.length - 1">,</span>
									</router-link>
								</span>
							</div>
						</td>
						<td scope="row" class="text-break text-pre-wrap content">
							<p v-if="notification['metaData']['contentDetail']">
								{{ notification["metaData"]["contentDetail"] }}</p
							>
							<a v-if="notification['metaData']['link']" :href="notification['metaData']['link']">
								Link
							</a>
						</td>
						<td scope="row" class="text-break text-pre-wrap body">{{
							notification["metaData"]["action"]
						}}</td>
						<td scope="row" class="text-nowrap createdAt">{{ notification.createdAt }}</td>
					</tr>
				</template>

				<template v-else>
					<tr class="text-center">
						<td colspan="12" class="fit">
							{{ $t("Table.NoData") }}
						</td>
					</tr>
				</template>
			</template>
		</CustomTableNotification>
	</div>
</template>

<script>
import { createNamespacedHelpers, mapState } from "vuex"
import CustomTableNotification from "../../../shared/components/CustomTableNotification"
const { mapActions, mapMutations } = createNamespacedHelpers("notifications")
import { GET_LIST_NOTIFICATION } from "../store/action-types"
import { GET_LIST_NOTIFICATION_SUCCESS } from "../store/mutation-types"
import { GROUP_NOTIFICATION_TYPE } from "../../../shared/plugins"

export default {
	name: "AdminNotificationManagement",
	components: {
		CustomTableNotification,
	},
	props: {},

	data() {
		return {
			headers: [
				{
					title: this.$t("NotificationHeader.No"),
					class: "fit",
				},
				{
					title: this.$t("NotificationHeader.Title"),
					class: "text-nowrap title",
				},
				{
					title: this.$t("NotificationHeader.Body"),
					class: "text-nowrap body",
				},
				{
					title: this.$t("NotificationHeader.Receiver"),
					class: "text-nowrap receiver",
				},
				{
					title: this.$t("NotificationHeader.Content"),
					class: "text-nowrap content",
				},
				{
					title: this.$t("NotificationHeader.Action"),
					class: "text-nowrap action",
				},
				{
					title: this.$t("NotificationHeader.CreatedDate"),
					class: "text-nowrap createdDate",
				},
			],
		}
	},

	computed: {
		...mapState("notifications", ["notifications", "totalItems", "totalPages", "recentFilters"]),
		...mapState("authentication", ["currentUser"]),
	},

	watch: {},

	created() {},

	methods: {
		...mapActions({ GET_LIST_NOTIFICATION }),
		...mapMutations({ GET_LIST_NOTIFICATION_SUCCESS }),
		goToCreateForm() {
			this.$router.push({ name: "CreateNotification" })
		},
		search(params) {
			this.currentParam = params
			this.GET_LIST_NOTIFICATION(params)
		},
		getNo(no) {
			return no + 1 + (+this.recentFilters.offset || 0)
		},
		getUserList(data) {
			// eslint-disable-next-line no-unused-vars
			let listUser = data.userId
		},
		shouldShowUser(notification) {
			return (
				notification.groupUser != null && notification.groupUser !== GROUP_NOTIFICATION_TYPE.PERSONS
			)
		},
		getNotiLabel(value) {
			return {
				[GROUP_NOTIFICATION_TYPE.ALL]: this.$t("NotificationForm.ALL"),
				[GROUP_NOTIFICATION_TYPE.PREP]: this.$t("NotificationForm.PREP"),
				[GROUP_NOTIFICATION_TYPE.ALL_DEVICE]: this.$t("NotificationForm.ALL_DEVICE"),
			}[value]
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.notification-list {
	table {
	}
}

.banner-image {
	max-width: 80px;

	@media (min-width: $xxs) {
		max-width: 80px;
	}
}

.status {
	&--active {
		color: $color-sea-green;
	}

	&--inactive {
		color: $color-red;
	}
}
</style>
